<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="clearfix">
    <vs-input
      v-validate="'required|alpha_spaces|min:3|max:50'"
      maxlength="50"
      data-vv-validate-on="blur"
      label-placeholder="Name"
      name="displayName"
      placeholder="Name"
      v-model="displayName"
      class="w-full"
    />
    <span class="text-danger text-sm">{{ errors.first("displayName") }}</span>

    <vs-input
      v-validate="'required'"
      maxlength="100"
      data-vv-validate-on="blur"
      name="company"
      label-placeholder="Company Name"
      placeholder="Company Name"
      v-model="companyName"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("company") }}</span>

    <div class="w-full mt-6">
      <p>Are you a Kotak customer ?</p>
      <ul class="flex">
        <li class="mr-2">
          <vs-radio vs-name="kotakcust" v-model="isKotakCustomer" vs-value="Yes"
            >Yes</vs-radio
          >
        </li>
        <li>
          <vs-radio vs-name="kotakcust" v-model="isKotakCustomer" vs-value="No"
            >No</vs-radio
          >
        </li>
      </ul>
    </div>

    <vs-input
      v-validate="'required|email'"
      maxlength="100"
      data-vv-validate-on="blur"
      label-placeholder="Email"
      placeholder="Email"
      name="email"
      v-model="email"
      class="mt-6 w-full"
    />
    <span class="text-danger text-sm" v-show="errors.has('email')">{{
      errors.first("email")
    }}</span>

    <vs-input
      v-validate="'required|numeric|max:10|min:10'"
      maxlength="10"
      data-vv-validate-on="blur"
      name="mobile"
      type="tel"
      label-placeholder="Mobile"
      placeholder="Mobile"
      v-model="mobile"
      @blur="checkMobileNumber"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("mobile") }}</span>

    <div class="flex justify-between">
      <div class="w-1/2">
        <vs-input
          type="password"
          maxlength="6"
          v-validate="'min:6|max:6'"
          name="opt"
          label-placeholder="OTP"
          placeholder="OTP"
          v-model="otp"
          class="w-full mt-6"
        />
      </div>
      <vs-button
        class="float-right mt-6"
        v-if="!isOTPGenerated"
        @click="generateOTP"
        :disabled="!validateGenerateOTP || disableOTP"
      >
        {{ isOTPGenerated ? "Regenerate OTP" : "Generate OTP" }}
      </vs-button>
    </div>

    <vs-input
      name="crn"
      maxlength="100"
      label-placeholder="CRN (Optional)"
      placeholder="CRN (Optional)"
      v-model="crn"
      class="w-full mt-6"
    />

    <div class="w-full mt-6">
      <p>Require an intimation of new updates on Whatsapp</p>
      <ul class="flex">
        <li class="mr-2">
          <vs-radio
            vs-name="whatsapp"
            v-model="isWhatsappAccepted"
            vs-value="Yes"
            >Yes</vs-radio
          >
        </li>
        <li>
          <vs-radio
            vs-name="whatsapp"
            v-model="isWhatsappAccepted"
            vs-value="No"
            >No</vs-radio
          >
        </li>
      </ul>
    </div>

    <div class="flex">
      <vs-checkbox
        v-model="isTermsConditionAccepted"
        class="mt-6"
      ></vs-checkbox>
      <p class="mt-6">
        I accept the
        <a class="cursor-pointer" @click.prevent="openTerms"
          >terms & conditions</a
        >.
      </p>
    </div>
    <vs-button type="border" to="/pages/kotaklogin" class="mt-6"
      >Go to login</vs-button
    >

    <vs-button
      class="float-right mt-6"
      @click="registerUserJWt"
      :disabled="!validateForm"
      >Register</vs-button
    >
  </div>
</template>

<script>
import jwt from "jsonwebtoken";
import Datepicker from "vuejs-datepicker";
//import axios from 'axios';
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const userRepository = RepositoryFactory.get("user");
const notificationRepository = RepositoryFactory.get("notifications");
const uuid = require("@/Core/config/uuid");
import reCaptchaMixin from "@/Client/Kotak/mixins/reCaptchaMixin";
export default {
  mixins: [reCaptchaMixin],
  data() {
    return {
      displayName: "",
      email: "",
      mobile: "",
      isTermsConditionAccepted: true,
      otp: "",
      verified: false,
      generatedOTP: 0,
      isInvited: false,
      communityManagers: [],
      companyName: "",
      isKotakCustomer: "Yes",
      crn: "",
      isWhatsappAccepted: "Yes",
      isOTPGenerated: false,
      sendToIndividualPost: false,
      individualPostData: {},
      disableOTP: false,
      sendToFXCalendar: false,
      sendToMMCalendar: false,
      sendToRoute: "",
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.displayName != "" &&
        this.isTermsConditionAccepted === true &&
        this.mobile != "" &&
        this.email != "" &&
        this.otp != ""
      );
    },
    validateGenerateOTP() {
      return (
        this.mobile != "" &&
        !this.errors.first("mobile") &&
        this.mobile.length > 9
      );
    },
  },
  mounted() {
    if (this.$router.currentRoute.params.toIndividualPost) {
      this.sendToIndividualPost = true;
      this.individualPostData = this.$router.currentRoute.params.pid;
      this.mobile = this.$router.currentRoute.params.mobile;
      this.crn = this.$router.currentRoute.params.crn;
    }
    if (this.$router.currentRoute.params.toFXCalendar) {
      this.sendToFXCalendar = true;
    }
    if (this.$router.currentRoute.params.toMMCalendar) {
      this.sendToMMCalendar = true;
    }
    if (this.$router.currentRoute.params.toRoute) {
      this.sendToRoute = this.$router.currentRoute.params.toRoute;
    }
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    async registerUserJWt() {
      // if (this.generatedOTP != this.otp) {
      //   console.log(this.generatedOTP);
      //   console.log(this.otp);

      //   window.alert("Incorrect OTP");
      //   this.verified = false;
      //   this.otp = null;
      // }
      // // If form is not validated or user is already login return
      // else if (!this.validateForm) {
      //   return;
      // } else {
      this.$vs.loading();
      // const reCaptcha = await this.recaptcha("readerRegister");
      const uniqueId = uuid.getUniqueBrowserId();
      localStorage.setItem("biri", uniqueId);

      const payload = {
        name: this.displayName,
        email: this.email,
        mobile: this.mobile,
        source: "Website",
        status: this.isInvited ? 1 : 0,
        communityName: currentCommunityName.communityName,
        otp: this.otp,
        uniqueId: uniqueId,
        whatsapp: this.isWhatsappAccepted,
        // token: reCaptcha
      };

      const { data } = await userRepository.addNormalUser(payload);
      ////debugger;

      // if (data.data.message == "Correct OTP") {
      if (data.data.message == "Login Successful.") {
        this.$vs.loading.close();
        this.$acl.change("normal");
        localStorage.setItem("token", data.token);
        this.$store.state.token = jwt.decode(localStorage.getItem("token"));
        this.$session.start();
        this.$session.set("jwt", data.token);
        this.addProfileData(data.data.userID).then(() => {
          this.emptyForm();
          if (this.sendToIndividualPost) {
            this.routeToIndividualPost();
          } else if (this.sendToFXCalendar) {
            this.$router.push({ name: "fx-calendar" });
          } else if (this.sendToMMCalendar) {
            this.$router.push({ name: "money-market-calendar" });
          } else if (this.sendToRoute) {
            this.$router.push({ name: this.sendToRoute });
          } else {
            this.$swal({
              icon: "success",
              title: "You have successfully registered",
            });
          }

          // this.$router.push("/community-home").catch(() => {});
          // this.linkGenerationForEmailVarification(data.data.uuid);
        });
      } else if (data.data.message == "Incorrect OTP") {
        this.$vs.loading.close();
        window.alert(data.data.message);
        this.verified = false;
        this.otp = null;
      }
      // }
    },
    async linkGenerationForEmailVarification(uuid) {
      let obj = {
        communityName: currentCommunityName.communityName,
        uuid: uuid,
      };
      obj = JSON.stringify(obj);
      const payload = {
        from: "manish.kini@magitt.com", // Static email
        to: this.email,
        subject: currentCommunityName.communityName + "Email Varification Link",
        body:
          process.env.VUE_APP_BASE_URL_COR_API2 +
          "/secureApi/emailVarification/" +
          btoa(obj),
        communityName: currentCommunityName.communityName,
      };

      await userRepository.SendLinkForEmailVarification(payload);
    },
    async generateOTP() {
      this.$vs.loading();
      const payload = {
        mobile: this.mobile,
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await userRepository.sendOTP(payload);
      if (data && data.success) {
        this.$vs.loading.close();
        this.verified = true;
        // this.$vs.notify({
        //   time: 8000,
        //   title: "OTP",
        //   text: data.OTP,
        //   iconPack: "feather",
        //   icon: "icon-alert-circle",
        //   color: "success"
        // });
      } else if (data && !data.success) {
        this.$vs.loading.close();
        this.disableOTP = true;
        this.$vs.notify({
          time: 8000,
          title: "Error",
          text: data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      } else {
        this.$vs.loading.close();
      }
    },
    async checkMobileNumber() {
      this.$vs.loading();
      const payload = {
        phoneNumber: this.mobile,
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await userRepository.checkPhoneNumber(payload);
      if (data.length > 0) {
        this.$vs.loading.close();
        this.mobile = "";
        this.$vs.notify({
          title: "Error",
          text: "User already created",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      } else {
        this.$vs.loading.close();
        this.checkInvitationStatus();
      }
    },
    async checkInvitationStatus() {
      this.$vs.loading();
      const payload = {
        phoneNumber: this.mobile,
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await userRepository.checkInvitationStatus(payload);

      if (data.length > 0) {
        this.isInvited = true;
        this.$vs.loading.close();
      } else {
        this.isInvited = false;
        this.$vs.loading.close();
      }
    },
    async getCommunityManagers(userdata) {
      this.$vs.loading();
      const payload = {
        communityName: currentCommunityName.communityName,
      };
      const { data } = await userRepository.getCommunityManagers(payload);

      if (data) {
        this.$vs.loading.close();
        this.communityManagers = data.map((x) => x.usersDetails_UniqueId);
        this.insertInvitationNotification(userdata);
      }
    },
    async insertInvitationNotification(userdata) {
      this.$vs.loading();
      let payload = {
        notificationData: {
          initiaterId: userdata.data.uuid,
          initiaterName: userdata.data.username,
          initiaterImage: userdata.data.userImage,
          recepientId: this.communityManagers,
          recepientType: 1,
          notificationMessage: `${userdata.data.username} (${userdata.data.userMobile}) wants to join the community`,
          notificationTitle: "Community Join Request",
          notificationEntityType: "Community Join",
          notificationEntityId: userdata.data.uuid,
          notificationReadStatus: 0,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await notificationRepository.addNotification(payload);
      if (data) {
        this.$vs.loading.close();
        this.$vs.notify({
          color: "success",
          title: "Community Join Request Sent",
        });
      }
    },
    openTerms() {
      // this.$router.push("/pages/terms").catch(() => {});
      let routeData = this.$router.resolve({ name: "page-terms" });
      window.open(routeData.href, "_blank");
    },
    async addProfileData(userId) {
      this.$vs.loading();
      let payload = {
        values: [
          {
            user_profile_details_user_id: userId,
            user_profile_details_profile_detail_master_id: 1,
            user_profile_details_value: this.companyName,
          },
          {
            user_profile_details_user_id: userId,
            user_profile_details_profile_detail_master_id: 2,
            user_profile_details_value: this.isKotakCustomer,
          },
          {
            user_profile_details_user_id: userId,
            user_profile_details_profile_detail_master_id: 3,
            user_profile_details_value: this.crn,
          },
          {
            user_profile_details_user_id: userId,
            user_profile_details_profile_detail_master_id: 4,
            user_profile_details_value: this.isWhatsappAccepted,
          },
          {
            user_profile_details_user_id: userId,
            user_profile_details_profile_detail_master_id: 5,
            user_profile_details_value: this.isTermsConditionAccepted,
          },
        ],
        communityName: currentCommunityName.communityName,
      };
      const { data } = await userRepository.addUserProfileData(payload);
      if (data) {
        this.$vs.loading.close();
      }
    },
    emptyForm() {
      this.displayName = "";
      this.email = "";
      this.mobile = "";
      this.isTermsConditionAccepted = true;
      this.otp = "";
      this.verified = false;
      this.generatedOTP = 0;
      this.isInvited = false;
      this.communityManagers = [];
      this.companyName = "";
      this.isKotakCustomer = "Yes";
      this.crn = "";
      this.isWhatsappAccepted = "Yes";
      this.isOTPGenerated = false;
    },
    routeToIndividualPost() {
      setTimeout(() => {
        this.$router
          .push("/posts?pid=" + this.$router.currentRoute.params.pid)
          .catch(() => {});
      }, 1000);
    },
  },
  components: {
    Datepicker,
  },
};
</script>
<style></style>
